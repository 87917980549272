import axios from 'axios'

export default {
  add ({ service, product, count }) {
    return this.send({ 
      action: 'add', 
      id: product, 
      service,
      count,
    })
  },

  update ({ service, product, count }) {
    return this.send({ 
      action: 'update', 
      id: product, 
      service,
      count,
    })
  },

  delete ({ service, product }) {
    return this.send({ 
      action: 'delete', 
      id: product, 
      service,
    })
  },

  send ({ service, action, id, count }) {
    const { lang } = document.documentElement
    const formData = new FormData()
    formData.append('action', action)
    formData.append('lang', lang) 
    formData.append('id', id)
    if (action != 'delete')
      formData.append('qta', count) 
    return axios.post(service, formData)
  },

  voucher ({ service, voucher }) {
    const { lang } = document.documentElement
    const formData = new FormData()
    formData.append('voucher', voucher)
    formData.append('lang', lang)
    return axios.post(service, formData)
  },

  getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  checkCode () {

  }
}