import { Component } from 'yuzu'
import { addClass, removeClass, isTouch } from '../base/utils'

export default class SearchBar extends Component {

  selectors = {
    'submit': '[data-search-submit]',
    'input': '[data-search-input]',
    'clear': '[data-search-clear]',
    'form': '[data-search-form]',
  }

  listeners = {
    'focus @input': event => {
      this.setState(() => ({
        focus: true
      }))
    },

    'blur @input': event => {
      this.setState(() => ({
        focus: false
      }))
    },

    'click @clear': event => {
      event.preventDefault()
      const { input } = this.$els
      input.value = ""
    },

    'click @submit': event => {
      const { input } = this.$els
      const value = input.value
      if (value.length <= 0)
        event.preventDefault()
    }
  }

  state = {
    open: false,
    focus: false,
  }

  actions = {
    open: 'toggle',
    focus: 'focus',
  }

  setup () {
    const $el = this.$el
    const className = `${$el.classList[0]}--ready`
    addClass($el, className)
  }

  toggle () {
    const $el = this.$el
    const { open } = this.state 
    const className = `${$el.classList[0]}--active`
    const action = open ? addClass : removeClass
    action($el, className)
  }

  focus () {
    const { open, focus } = this.state
    if (!focus && open) 
      this.setState(() => ({
        open: false
      }))
  }

  mounted () {
    this.setup()
  }
}