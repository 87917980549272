import { Component } from 'yuzu'
import { TimelineMax, Linear } from 'gsap/all'
import { qs, addClass, removeClass, hasClass, validateEmail } from '../base/utils'
import axios from 'axios'

export default class Newsletter extends Component {

  static root = '.c-newsletter'

  selectors = {
    'svg': '[data-nl-svg]',
    'form': '[data-nl-form]',
    'input': '[data-nl-input]',
    'locale': '[data-nl-lang]',
    'submit': '[data-nl-submit]',
    'circle': '[data-nl-circle]',
    'result': '[data-nl-result]',
    'checkbox': '[data-nl-privacy]',
    'feedback': '[data-nl-feedback]',
    'field': '[data-nl-submit-field]',
  }

  listeners = {
    'keydown @input': event => {
      this.onChange()
    },

    'change @checkbox': event => {
      this.onChange()
    },

    'click @submit': event => {
      event.preventDefault()
      this.submit()
    }
  }

  state = {
    sending: false,
    submit: false,
    sended: false,
    message: null,
    result: null,
    valid: null,
  }

  actions = {
    message: 'message',
    sending: 'sending',
    valid: 'validate',
    sended: 'sended',
    status: 'status',
  }

  setup () {
    const { feedback} = this.$els
    this.errors = {
      privacy: qs('.privacy-error', feedback).innerHTML,
      email: qs('.email-error', feedback).innerHTML,
    }
  }

  loop () {
    const { svg, circle } = this.$els
    return new TimelineMax({ tweens: [
      TweenMax.to(svg, 1.4, { rotation: 360, ease: Linear.easeNone }),
      TweenMax.to(circle, 1.4, { bezier: { values:[ 
        { 'stroke-dasharray': '100, 200', 'stroke-dashoffset': -15 }, 
        { 'stroke-dasharray': '100, 200', 'stroke-dashoffset': -125 },
        ], curviness: 0 }, ease: 'Quad.easeInOut' })
    ], repeat: -1, })
  }

  onChange () {
    const { valid } = this.state
    if (!valid)
      this.setState(() => ({ valid: null }))
  }

  submit () {
    const { input, checkbox, locale, form } = this.$els
    const { sending } = this.state
    const privacy = checkbox.checked
    const action = form.action
    const email = input.value
    const lang = locale.value
    const debug = false

    if (sending) return
    
    this.setState(() => ({ submit: true }))
    
    if (!validateEmail(email)) 
      this.setState(() => ({ 
        message: this.errors.email,
        valid: false,
      }))
    else if (!privacy)
      this.setState(() => ({ 
        message: this.errors.privacy,
        valid: false,
      }))
    else {
      this.setState(() => ({ sending: true }))
      axios.post(action, { email, lang, privacy, debug }).then(response => {
        const data = response.data
        const message = data.message
        const error = data.error
        if (error) 
          this.setState(() => ({ 
            sending: false,
            valid: false,
            message,
          }))
        else this.setState(() => ({ 
            status: message,
            sending: false,
            sended: true
          }))
      })
    }
  }

  message () {
    const { feedback} = this.$els
    const { message } = this.state
    if (message)
      qs('p', feedback).innerHTML = message
  }

  validate () {
    const { feedback } = this.$els
    const { valid, submit } = this.state
    const className = `${feedback.classList[0]}--active`
    const action = !valid ? addClass : removeClass
    if (submit) {
      if (valid == null)
        removeClass(feedback, className)
      else action(feedback, className)
    }
  }

  sending () {
    const { sending } = this.state
    const { field, input } = this.$els
    const className = `${field.classList[0]}--sending`
    const action = sending ? addClass : removeClass
    input.disabled = sending
    action(field, className)
  }

  status () {
    const { result} = this.$els
    const { status } = this.state
    console.log(status)
    if (status)
      qs('p', result).innerHTML = status
  }

  sended () {
    const { sended } = this.state
    const className = `${this.$el.classList[0]}--sended`
    const action = sended ? addClass : removeClass
    action(this.$el, className)
  }
  
  mounted () {
    this.setup()
    this.loop()
  }
}