import { Component } from 'yuzu'

export default class SharePage extends Component {

  static root = '.c-share'

  selectors = {
    'socials[]': '[data-social-icon]'
  }

  listeners = {
    'click @socials': event => {
      event.preventDefault()
      const social = event.currentTarget.id
      this.share(social)
    },
  }

  share (social) {
    let sharer
    let title = document.title
    let href = window.location.href
    
    switch (social) {
      case 'fb' :
        sharer = `https://www.facebook.com/sharer/sharer.php?u=${href}`
        break
      case 'tw' :
        sharer = `https://twitter.com/home?status=${href}`
        break
      case 'in' :
        sharer = `https://www.linkedin.com/shareArticle?mini=true&url=${href}&title=${title}&summary=&source=`
        break
    }

    this.popup(sharer, '', 640, 480)
  }

  popup (url, title, w, h) {
    let x = (screen.width / 2) - (w / 2)
    let y = (screen.height / 2) - (h / 2)
    return window.open(url, title, `width=${w},height=${h},top=${y},left=${x},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
  }

  mounted () {
    return
  }
}