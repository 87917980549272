import { Component } from 'yuzu'
import { parents } from '../base/utils'
import { TweenMax } from 'gsap/all'
import events from '../events'
import cart from '../services/cart'

export default class CartProd extends Component {

  static root = '.c-products-cart'

  selectors = {
    'id': '[data-hide-id]',
    'add': '[data-add]',
    'del': '[data-del]',
    'counter': '[data-count]',
    'pricer': '[data-price]',
    'remove': '[data-remove]',
    'vat': '[data-hide-vat]',
    'unit': '[data-hide-price]',
    'total': '[data-hide-total]',
    'required': '[data-hide-required]',
    'available': '[data-hide-available]',
  }

  listeners = {
    'click @add': event => {
      const { count, max } = this.state
      if (count < max)
        this.setState(({ count }) => ({
          count: count+1,
          event,
        }))
    },

    'click @del': event => {
      const { count, min } = this.state
      if (count > min)
        this.setState(({ count }) => ({
          count: count-1,
          event,
        }))
    },

    'change @counter': event => {
      const { min, max } = this.state
      const { target } = event
      let index = parseInt(target.value)
      if (index < min) index = min
      if (index > max) index = max
      this.setState(({ count }) => ({
        count: index,
        event,
      }))
    },

    'click @remove': event => {
      this.remove()
    }
  }

  state = {
    min: -1,
    max: -1,
    vat: -1,
    count: 1,
    price: -1,
    event: null,
  }

  actions = {
    count: 'update'
  }

  setup () {
    const { vat, unit, counter, required, available } = this.$els
    this.setState(() => ({
      min: parseFloat(required.value),
      max: parseFloat(available.value),
      count: parseFloat(counter.value),
      vat: parseFloat(vat.value.replace(',', '.')),
      price: parseFloat(unit.value.replace(',', '.')),
    }))
  }

  update () {
    if (this.state.price < 0) return

    const { count, price, vat, event } = this.state
    const { id, counter, pricer } = this.$els
    const service = this.$el.dataset.action
    const product = parseInt(id.value)
    const total = price * count
    const tvat = vat * count
    counter.value = count
    pricer.innerHTML = total.toFixed(2).replace('.', ',')
    if (!event)
      events.emit('cart_update', { product, count, total, tvat })
    else cart.update({ service, product, count }).then(response => {
        const data = response.data
        const shipping = parseFloat(data.shipping_price.replace(',', '.'))
        events.emit('cart_update', { product, count, total, tvat, shipping })
        events.emit('cart_sync', parseInt(data.total))
      })
  }

  remove () {
    const { id } = this.$els
    const service = this.$el.dataset.action
    const product = parseInt(id.value)
    cart.delete({ service, product }).then(response => {
      const data = response.data
      const shipping = parseFloat(data.shipping_price.replace(',', '.'))
      events.emit('cart_destroy', { product, shipping })
      events.emit('cart_sync', parseInt(data.total))
      this.destroy()
    })
  }

  beforeDestroy () {
    const ul = parents(this.$el, 'ul')
    const li = parents(this.$el, 'li')
    TweenMax.to(li, .6, { autoAlpha: 0, ease: 'Cubic.easeOut', onComplete: () => ul.removeChild(li) })
  }

  mounted () {
    this.setup()
    this.update()

    //console.log(cart.getCookie('cart_gmi_item'))
  }
}