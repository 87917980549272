import { Component } from 'yuzu'
import track from '../tracking'

export default class Payment extends Component {
  
  static root = '.c-select-payment'

  selectors = {
    'form': '[data-payment-form]'
  }

  listeners = {
    'submit @form': event => {
      event.preventDefault()
      track.ga('begin_checkout', 'ecommerce')
      track.fb('InitiateCheckout')
      this.$els.form.submit()
    }
  }
}