import { Component } from 'yuzu'
import { isWebGLAvailable } from '../base/utils'
import Sketch from '../webgl/sketch'

export default class HeroEffect extends Component {
  
  static root = '.c-hero'

  selectors = {
    'effect': '[data-effect]',
  }

  state = {
    active: null
  }

  actions = {
    active: 'setup'
  }

  setup () {
    const { active } = this.state
    const { effect } = this.$els
    if (active === null) return
    if (active)
      this.sketch = new Sketch(effect)
    else this.fallback(effect)
  }

  fallback (effect) {
    const source = effect.getAttribute('data-imageOriginal')
    effect.style.backgroundImage = `url(${source})`
  }

  mounted () {
    this.setState(() => ({ 
      active: isWebGLAvailable() && this.$els.effect
    }))
  }
}