import { Component } from 'yuzu'
import { TweenMax } from 'gsap/all'
import { addClass, removeClass } from '../base/utils'
import events from '../events'
import cart from '../services/cart'
import gift from '../services/gift'

export default class CartList extends Component {

  static root = '.c-products-list'

  selectors = {
    'submit': '[data-submit]',
    'input': '[data-voucher]',
    'message': '[data-message]',
    'subtotal': '[data-subtotal] .price',
    'discount': '[data-discount] .price',
    'shipping': '[data-shipping] .price',
    'subvat': '[data-vat] .price',
    'total': '[data-total] .price',
    'list': '[data-products-list] ul',
  }

  listeners = {
    'keydown @input': event => {
      this.toggleMessage(false)
    },
    
    'click @submit': event => {
      event.preventDefault()
      this.sendVoucher()
    }
  }

  state = {
    discount: 0,
    shipping: 0,
    subtotal: 0,
    subcount: -1,
    subvat: 0,
    total: 0,
  }

  actions = {
    subtotal: 'subtotal',
    discount: 'subtotal',
    total: 'total',
  }

  setup () {
    const { discount, shipping } = this.$els
    this.setState(() => ({
      discount: parseFloat(discount.innerHTML.replace(',', '.')),
      shipping: parseFloat(shipping.innerHTML.replace(',', '.'))
    }))
    
    this.addListeners()
  }

  addListeners () {
    this.products = {}
    events.on('cart_update', ({ product, count, total, tvat, shipping }) => {
      if (!this.products[product])
        this.products[product] = {}
      this.products[product].count = count
      this.products[product].total = total
      this.products[product].vat = tvat
      this.setState(() => ({
        shipping: shipping
      }))
      this.sync()
      this.sendVoucher()
    })

    events.on('cart_destroy', ({ product, shipping }) => {
      if (!this.products[product]) return
      this.products[product].count = 0
      this.products[product].total = 0
      this.products[product].vat = 0
      this.setState(() => ({
        shipping: shipping
      }))
      this.sync()
      this.sendVoucher()
    })
  }

  sync () {
    let subtotal = 0
    let subcount = 0
    let subvat = 0
    const { products } = this
    for (let id in products) {
      const count = products[id].count
      const total = products[id].total
      const vat = products[id].vat
      subcount += count
      subtotal += total
      subvat += vat
    }
    this.setState(() => ({ subcount, subvat, subtotal }))
  }
  
  subtotal (curr, prev) {
    if (curr == prev) return
    const { subtotal, subvat, discount, shipping } = this.state
    this.$els.subtotal.innerHTML = subtotal.toFixed(2).replace('.', ',')
    this.$els.subvat.innerHTML = subvat.toFixed(2).replace('.', ',')
    this.$els.shipping.innerHTML = shipping.toFixed(2).replace('.', ',')
    this.$els.discount.innerHTML = discount.toFixed(2).replace('.', ',')
    this.setState(() => ({ total: subtotal + subvat - discount + shipping }))
  }

  total (curr, prev) {
    if (curr == prev) return
    const { subcount, subvat, total } = this.state
    this.$els.total.innerHTML = total.toFixed(2).replace('.', ',')
    if (subcount == 0) {
      location.reload()
    }
  }

  sendVoucher () {
    const service = this.$el.dataset.action
    const voucher = this.$els.input.value
    
    if (voucher.length <= 0) return
    
    cart.voucher({ service, voucher }).then(response => {
      const { success, message, discount, gifts } = response.data
      this.$els.submit.disbled = success
      const className = success ? 'success' : 'error'
      this.toggleMessage(true, message, className)
      this.setState(() => ({
        discount: parseFloat((success ? discount : '0,00').replace(',', '.'))
      }))

      gift.clear()
      
      if (success) {
        if (gifts.length) {
          for (let i = 0; i < gifts.length; ++ i) {
            const item = gift.createElement(gifts[i])
            this.$els.list.appendChild(item)
            
            TweenMax.from(item, .8, { autoAlpha: 0, delay: i * .1, ease: 'Cubic.easeInOut' })
          }
        }
      }

      /*if (success) 
        this.setState(() => ({
          discount: parseFloat(discount.replace(',', '.'))
        }))*/
    })
  }

  toggleMessage (toggle, message, className) {
    const $el = this.$els.message
    removeClass($el, 'success')
    removeClass($el, 'error')
    if (className) 
      addClass($el, className) 
    if (!toggle)
      removeClass($el, 'active')
    else addClass($el, 'active')
    if (message)
      $el.innerHTML = message
  }

  mounted () {
    this.setup()
  }
}