import { Component } from 'yuzu'
//import MicroModal from 'micromodal'
import MicroModal from './micromodal'
import * as Swiper from 'swiper/dist/js/swiper.js'
import Swipe from 'swipejs'
import PinchZoom from 'pinch-zoom-js'

import { qs, addClass, removeClass, stringToDOM, isTouch } from '../base/utils'

export default class CardSwiper extends Component {

  static root = '.swiper'
  
  selectors = {
    'swiper': '[data-swiper]',
    'wrapper': '[data-swiper-wrapper]',
    'slides[]': '[data-swiper-item]',
    'prevbutton': '[data-swiper-prev]',
    'nextbutton': '[data-swiper-next]',
    'pagination': '[data-swiper-pagination]',
  }

  state = {
    needSwipe: true
  }

  listeners = {
    'click @slides': (e) => {
      this.appendZoom(e)
    }
  }

  actions = {
    needSwipe: 'syncSwiper'
  }

  appendZoom(e) {
    const img = qs('img', e.currentTarget)

    if (!img.hasAttribute('data-zoom-src')) {
      return
    }

    const appendFunction = (modal) => {
      if (this.zoomed) {
        qs('.zoomed-image', modal).removeChild(this.zoomed)
      }

      this.zoomed = stringToDOM(`<img src="${img.getAttribute('data-zoom-src')}"/>`)
      qs('.zoomed-image', modal).appendChild(this.zoomed)

      if (isTouch()) {
        this.swipe = new Swipe(qs('#zoom'))
        this.pinchZoom = new PinchZoom(qs('.zoomed-image'))
      }
    }

    const destroyFunction = () => {
      if (isTouch()) {
        this.swipe.kill()
        this.pinchZoom.disable()
      }
    }

    MicroModal.show('zoom', {
      disableScroll: true,
      onShow: appendFunction,
      onClose: destroyFunction
    })
  }

  initSwiper () {
    if (qs('#zoom')) {
      setTimeout(() => {
        qs('#zoom').style.display = 'block'
      }, 400)
    }

    const { swiper, pagination, prevbutton, nextbutton } = this.$els
    const scope = this

    this.swiper = new Swiper(swiper, {
      //centeredSlides: true,
      slidesPerView: 'auto',
      pagination: {
          el: pagination,
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 1,
      },
      navigation: {
        prevEl: prevbutton,
        nextEl: nextbutton,
      },
      on: {
        init () {
          setTimeout(() => {
            scope.resize()
          })
        }
      }
    })
  }

  syncSwiper () {
    const { swiper } = this.$els
    const { needSwipe } = this.state
    const className = `${swiper.classList[0]}--active`

    if (this.swiper) {
      this.swiper.allowSlidePrev = needSwipe
      this.swiper.allowSlideNext = needSwipe
    }
    if (!needSwipe)
      removeClass(swiper, className)
    else addClass(swiper, className)
  }

  resize () {
    const { needSwipe } = this.state
    const newState = this.swiper.pagination.bullets.length > 1
    if (newState != needSwipe) {
      this.setState(() => ({ needSwipe: newState }))
    }
  }

  mounted() {
    this.initSwiper()
  }
}