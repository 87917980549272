import { Component } from 'yuzu'
import { TimelineMax } from 'gsap';

export default class AccordionGroup extends Component {
  static root = '[data-accordion]'

  selectors = {
    'triggers[]': '[data-accordion-trigger]',
    'panels[]': '[data-accordion-panel]'
  }

  state = {
    allowMultiple: false,
    allowToggle: false
  }

  listeners = {
    'click @triggers': (event, id) => {
      const target = this.$els.triggers[id]
      const { allowMultiple, allowToggle } = this.state

      const isExpanded = target.getAttribute('aria-expanded') === 'true'
      const active = this.$el.querySelector('[aria-expanded="true"]')

      if (!allowMultiple && active && active !== target) {
        active.setAttribute('aria-expanded', 'false')
        const el = this.$el.querySelector(`#${active.getAttribute('aria-controls')}`)
        AccordionGroup.close(el , () => { el.setAttribute('hidden', '') }).play(0)

        if (!allowToggle) {
          active.removeAttribute('aria-disabled')
        }
      }

      if (!isExpanded) {
        target.setAttribute('aria-expanded', 'true')
        const el = this.$el.querySelector(`#${target.getAttribute('aria-controls')}`)
        el.removeAttribute('hidden')
        AccordionGroup.open(el).play(0)

        if (!allowToggle) {
          target.setAttribute('aria-disabled', 'true')
        }
      } else if (allowToggle && isExpanded) {
        target.setAttribute('aria-expanded', 'false')
        const el = this.$el.querySelector(`#${target.getAttribute('aria-controls')}`)
        AccordionGroup.close(el, () => { el.setAttribute('hidden', '') }).play(0)
      }

      event.preventDefault()
    },

    'keydown @triggers': () => {
      const target = event.target
      const key = event.which.toString()

      // 33 = Page Up, 34 = Page Down
      const ctrlModifier = (event.ctrlKey && key.match(/33|34/))

      // Is this coming from an accordion header?
      if (target.classList.contains('accordion__trigger')) {
        if (key.match(/38|40/) || ctrlModifier) {
          const index = this.$els.triggers.indexOf(target)
          const direction = (key.match(/34|40/)) ? 1 : -1
          const length = this.$els.triggers.length
          const newIndex = (index + length + direction) % length

          this.$els.triggers[newIndex].focus()

          event.preventDefault()
        }
        else if (key.match(/35|36/)) {
          switch (key) {
            case '36':
              this.$els.triggers[0].focus()
              break
            case '35':
              this.$els.triggers[this.$els.triggers.length - 1].focus()
              break
          }

          event.preventDefault()
        }
      }
    },

    'focus @triggers': (event) => {
      this.$el.classList.add('focus')
    },

    'blur @triggers': (event) => {
      this.$el.classList.remove('focus')
    }
  }

  actions = {
  }

  mounted() {
    this.setState({ allowMultiple: this.$el.hasAttribute('data-allow-multiple') })
    const { allowMultiple } = this.state

    this.setState({ allowToggle: this.$el.hasAttribute('data-allow-toggle') })
    const { allowToggle } = this.state

    this.$els.panels.forEach((el, i) => {
      if (!allowMultiple && this.$els.triggers[i].getAttribute('aria-expanded') === 'false') {
        el.setAttribute('hidden', '')
      }
    })

    if (!allowToggle) {
      const expanded = this.$el.querySelector('[aria-expanded="true"]')
      if (expanded) {
        expanded.setAttribute('aria-disabled', 'true')
      }
    }
  }

  static close(el, callback = () => {}) {
    const tl = new TimelineMax({ paused: true, onComplete: callback })

    tl
      .to(el, 0.5, {
        height: 0,
        clearProps: 'all'
      })

    return tl
  }

  static open(el, callback = () => {}) {
    const tl = new TimelineMax({ paused: true, onComplete: callback })

    tl
    .set(el, { height: 'auto' })
    .from(el, 0.5, { height: 0 })

    return tl
  }
}
