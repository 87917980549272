import { Component } from 'yuzu'
import { addClass, hasClass, removeClass, isTouch, getScrollTop } from '../base/utils';
import SearchBar from './search-bar'
import NavPanel from './nav-panel'
import SubPanel from './sub-panel'
import NavBar from './nav-bar'
import events from '../events'

export default class HeaderTools extends Component {
  
  static root = 'header'

  selectors = {
    'navBar': '[data-nav-bar]',
    'navPanel': '[data-nav-panel]',
    'subPanel': '[data-sub-menu]',
    'hambIcon': '[data-hamb-icon]',
    'closeIcon': '[data-close-icon]',
    'searchBar': '[data-search-bar]',
    'searchIcon': '[data-search-icon]',
    'cartBadge': '[data-cart-badge]',
    'cartIcon': '[data-cart-icon]',
  }

  listeners = {
    'click @hambIcon': event => {
      event.preventDefault()
      const { navPanel } = this.$refs
      navPanel.setState(() => ({ open: true, hidden: false }))
    },

    'click @closeIcon': event => {
      event.preventDefault()
      const { navPanel } = this.$refs
      navPanel.setState(() => ({ open: false, hidden: false }))
    },

    'click @searchIcon': event => {
      event.preventDefault()
      const { searchBar } = this.$refs
      searchBar.setState(({ open }) => ({ open: !open }))
    },

    'mouseenter @searchBar': event => {
      if (isTouch()) return
      event.preventDefault()
      const { searchBar } = this.$refs
      searchBar.setState(() => ({ open: true }))
    },

    'mouseleave @searchBar': event => {
      if (isTouch()) return
      event.preventDefault()
      const { searchBar } = this.$refs
      if (!searchBar.state.focus)
        searchBar.setState(() => ({ open: false }))
    },

    'touchstart @searchBar': event => {
      if (!isTouch()) return
      const { searchBar } = this.$refs
      if (!searchBar.state.open) {
        event.preventDefault()
        searchBar.setState(() => ({ open: true }))
      }
    }
  }

  state = {
    scrolldir: null,
  }

  actions = {
    scrolldir: 'setScrollDirection',
  }

  setup () {
    const { cartBadge } = this.$els
    events.on('cart_sync', subcount => {
      if (subcount < 0) return
      cartBadge.innerHTML = `0${subcount}`.slice(-2)
      if (subcount <= 0 && !hasClass(cartBadge, 'empty'))
        addClass(cartBadge, 'empty')
      else if (subcount > 0 && hasClass(cartBadge, 'empty'))
        removeClass(cartBadge, 'empty')
    })
  }

  onNavPanelOpen (value) {
    const { hambIcon, closeIcon } = this.$els
    const hambActiveClassName = `${hambIcon.classList[0]}--active`
    const closeActiveClassName = `${closeIcon.classList[0]}--active`
    const hambAction = value ? removeClass : addClass
    const closeAction = value ? addClass : removeClass
    hambAction(hambIcon, hambActiveClassName)
    closeAction(closeIcon, closeActiveClassName)
    if (value) {
      const { searchBar } = this.$refs
      searchBar.setState(() => ({ open: false }))
    } else {
      const { subPanel } = this.$refs
      subPanel.setState(() => ({ open: false }))
    }
  }

  onNavPanelSub (options) {
    const { subPanel } = this.$refs
    subPanel.setState(() => ({ 
      index: options.index, 
      open: options.open, 
    }))
  }

  setScrollDirection () {
    const { navBar, searchBar } = this.$refs
    const { scrolldir } = this.state
    if (scrolldir) {
      if (searchBar.state.open && scrolldir == 'down')
        searchBar.setState(() => ({ 
          focus: false,
          open: false,
        }))

      navBar.setState(() => ({ 
        hidden: scrolldir == 'down' 
      }))
    }
  }

  pointerStart (event) {
    this.dispatch('pointerStart', event)
  }

  scroll (data) {
    const { scrolldir } = this.state
    const { scrollY, direction } = data
    
    let scrollTop = 0
    scrollTop = scrollY
    if (scrollTop <= 0)
      scrollTop = 0
    
    if (scrollTop != this.scrollStatus.first) {
      this.scrollStatus.first = null
      if (direction != scrolldir && scrollTop > 0) {
        this.setState(() => ({ scrolldir: direction }))
      }
    }

    this.dispatch('scroll', data)
  }

  dispatch = (fn, options) => {
    for (let key in this.$refs) {
      const children = this.$refs[key]
      if (children[fn])
        children[fn](options)
    }
  }

  created () {
    const scrollTop = getScrollTop()
    this.scrollStatus = {
      first: scrollTop,
      last: scrollTop,
      elapsed: -1,
      delay: 1000,
      timer: 0
    }
  }

  initialize () {
    const { navBar, navPanel, subPanel, searchBar } = this.$els
    this.setRef({ id: 'navBar', el: navBar, component: NavBar })
    this.setRef({ id: 'searchBar', el: searchBar, component: SearchBar })
    this.setRef({ id: 'subPanel', el: subPanel, component: SubPanel })
    this.setRef({ id: 'navPanel', el: navPanel, component: NavPanel, on: { 
      open: value => this.onNavPanelOpen(value),
      sub: options => this.onNavPanelSub(options),
    } })
  }

  mounted () {
    this.setup()
  }
}