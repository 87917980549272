import { Component } from 'yuzu'
import { TweenMax, Expo } from 'gsap/all'

export default class ScrollToTop extends Component {

  static root = '.c-to-top'

  selectors = {
    'toTop': '[data-to-top]'
  }

  listeners = {
    'click @toTop': event => {
      //TweenMax.to(window, 1.8, { scrollTo: { y: 0, autoKill: false }, ease: Expo.easeInOut })
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      TweenMax.to( { y: scrollTop }, 1.8, { y: 0, ease: Expo.easeInOut, onUpdate: tween => {
        this.scrollTo(0, tween.target.y) }, onUpdateParams: ["{self}"] })
    },
  }

  scrollTo (x, y) {
    if (window.scrollTo)       
      window.scrollTo(x, y)
    else window.scrollTop = y
  }

  mounted () {
    return
  }
}