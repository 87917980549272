import { Component } from 'yuzu'
import events from '../events'
import cart from '../services/cart'
import track from '../tracking'

export default class ProductLeaf extends Component {

  static root = '.c-product-detail'

  selectors = {
    'id': '[data-hide-id]',
    'send': '[data-send]',
    'add' : '[data-quantity-increment]',
    'del' : '[data-quantity-decrement]',
    'counter' : '[data-quantity-value]',
    'required': '[data-hide-required]',
    'available': '[data-hide-available]',
    'contacts': '[data-availability-contacts]',
  }

  listeners = {
    'click @add': event => {
      const { count, max } = this.state
      if (count < max)
        this.setState(({ count }) => ({
          count: count+1
        }))
    },
    
    'click @del': event => {
      const { count, min } = this.state
      if (count > min)
        this.setState(({ count }) => ({
          count: count-1
        }))
    },

    'click @send': event => {
      event.preventDefault()
      this.send()
    },

    'click @contacts': event => {
      event.preventDefault()
      events.emit('open_product_form', true)
    }
  }

  state = {
    min: -1,
    max: -1,
    count: 1,
  }

  actions = {
    count: 'update'
  }

  setup () {
    const { required, available } = this.$els
    if (available)
      this.setState(() => ({
        min: parseFloat(required.value),
        max: parseFloat(available.value.replace(',', '.')),
        count: parseFloat(required.value),
      }))
    
    events.on('swipe_unlocked', () => this.send())
  }

  update () {
    const { counter } = this.$els
    const { count } = this.state
    if (counter)
      counter.value = count
  }

  send () {
    const { id } = this.$els
    const { count } = this.state
    const service = this.$el.dataset.action
    const product = parseInt(id.value)
    cart.add({ service, product, count }).then(response => {
      const data = response.data
      events.emit('cart_sync', parseInt(data.total))
      track.ga('add_to_cart', 'ecommerce')
      track.fb('AddToCart')
    })
  }

  mounted () {
    this.setup()
  }
}