import inViewport from 'in-viewport'
import Scrollbar from '.'
import animations from './animations/animations'
import setters from './animations/setters'
import { isMobile, qsa, addClass } from '../base/utils'

export default class EnterAnimations {
  constructor() {
    this.ticking = false

    this.$els = []
    this.$els.animate = qsa('[data-animation]')

    this.$els.animate.forEach(el => {
      const type = el.getAttribute('data-animation')

      if (animations.get(type)) {
        addClass(el, `animation-${type}`)
      } else if (type === 'css') {
        addClass(el, 'css-animation')
      }
    })

    Scrollbar.add(this.listenScroll.bind(this))
    this.setInitialState()
    this.listenScroll()
  }

  listenScroll() {
    this.$els.animate.forEach((el, i) => {
      if (isMobile() && el.getAttribute('data-animation') === 'parallax') return

      if (inViewport(el) && el.getAttribute('data-animation') === 'parallax') {
        animations.get('parallax')(el, i)
      } else if (inViewport(el) && !el.hasAttribute('data-animated')) {
        el.setAttribute('data-animated', '')

        const type = el.getAttribute('data-animation')

        if (animations.get(type)) {
          animations.get(type)(el)
        } else if (type === 'css') {
          addClass(el, 'css-animation-enter')
        } else {
          addClass(el, `animation-${type}-enter`)
        }
      }
    })

    this.ticking = false
  }

  setInitialState() {
    this.$els.animate.forEach((el) => {
      if (isMobile() && el.getAttribute('data-animation') === 'parallax') return

      const type = el.getAttribute('data-animation')
      if (setters.get(type)) {
        setters.get(type)(el)
      }
    })
  }
}