import { Component } from 'yuzu'
import { removeClass, addClass } from '../base/utils'

export default class Input extends Component {

  static root = '[data-input]'

  state = {
    focus: false,
    flags: {
      valid: false,
      invalid: false
    },
    active: true
  }

  actions = {
    focus: 'setFocus',
    flags: 'validate'
  }

  selectors = {
    input: 'input',
    select: 'select',
    textarea: 'textarea',
  }

  listeners = {
    'focus input': () => {
      this.setState({ focus: true })
    },

    'blur input': () => {
      this.setState({ focus: false })
    },

    'focus select': () => {
      this.setState({ focus: true })
    },

    'blur select': () => {
      this.setState({ focus: false })
    },

    'focus textarea': () => {
      this.setState({ focus: true })
    },

    'blur textarea': () => {
      this.setState({ focus: false })
    }
  }

  setFocus(val) {
    if (val) {
      addClass(this.$el, 'focus')
    } else {
      removeClass(this.$el, 'focus')
    }
  }

  validate() {
    // 'valid': this.flags.valid && this.active,
    // 'invalid': this.flags.invalid && this.active,
  }
}