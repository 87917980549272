import { Component } from 'yuzu'
import { TweenMax, Power2 } from 'gsap'
import { removeClass, addClass } from '../base/utils'

export default class Warning extends Component {

  static root = '.warning'

  selectors = {
    'background': '[data-background]',
    'message': '[data-message]',
    'panel': '[data-panel]',
    'close': '[data-close]',
  }

  listeners = {
    'click @background': event => {
      this.setState(() => ({
        enabled: false
      }))
    },

    'click @close': event => {
      this.setState(() => ({
        enabled: false
      }))
    }
  }

  state = {
    enabled: true
  }

  actions = {
    enabled: 'sync'
  }

  setup () {
    this.addListeners()
  }

  addListeners () {
    window.addEventListener('DOMContentLoaded', () => setTimeout(() => this.resize()))
    window.addEventListener('resize', this.resize.bind(this))
  }

  resize () {
    const { panel, message } = this.$els
    const bbox = panel.getBoundingClientRect()
    const offset = window.innerHeight - (bbox.height + bbox.top * 2)
    const height = offset < 0 ? window.innerHeight - 340 : 'auto'
    TweenMax.set(message, { height })
  }

  sync () {
    const { $el } = this
    const { enabled } = this.state
    const { body } = document
    const className = `${$el.classList[0]}--enabled`
    const ease = Power2.easeOut
    const clearProps = 'all'
    const duration = .8
    if (enabled) 
      TweenMax.fromTo($el, duration, { opacity: 0 }, { opacity: 1, ease, 
        onStart: () => {
          addClass(body, className)
          addClass($el, className)
        } })
    else TweenMax.to($el, duration, { opacity: 0, ease, clearProps, 
        onStart: () => removeClass(body, className),
        onComplete: () => removeClass($el, className) })
  }

  mounted () {
    this.setup()
  }
}