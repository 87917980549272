import { Component } from 'yuzu'
import { qsa, qs } from 'yuzu-utils'
// import { TweenMax } from 'gsap'
import validator from 'validator'
import track from '../tracking'
import events from '../events'
import axios from 'axios'
import { format} from 'util'
import { stringToDOM, addClass, removeClass, hasClass } from '../base/utils'

export default class Form extends Component {
  static root = '.c-user-form'

  selectors = {
    submit: '[data-submit]',
    'inputGroup[]': '[data-input]',
    error: '[data-error-message]',
    dynamicForm: '[data-dynamic-form]',
    'input[]': 'input, select',
    'errors[]': '[data-error-value]',
    'changer[]': '[data-change]'
  }

  state = {
    formType: ''
  }

  listeners = {
    'change @changer': e => {
      this.setCurrentForm(e.currentTarget.id)
    },

    'click @submit': e => {
      // get only select, input in page without filter hidden fields
      this.inputs = qsa('select, input, textarea', qs('[data-page]')).filter(input => {
        if (!hasClass(input, `validate-excluded`)) {
          return input
        }
      })

      this.validate(e)
    },
  }

  mounted() {
    const changer = this.$els.changer.find(el => el.checked)

    if (changer) {
      this.setCurrentForm(changer.id)
    }
  }

  setCurrentForm(id) {
    if (this.$els.dynamicForm) {
      this.$el.setAttribute('data-form', id)
      this.setState({ formType: id})
      
      this.$els.inputGroup.forEach(el => {
        const input = qs('select, input', el)
        
        el.style.display = ''
        removeClass(input, 'validate-excluded')

        if (!hasClass(input, `is-${this.state.formType}`)) {
          el.style.display = 'none'
          addClass(input, 'validate-excluded')
        }
      })
    }
  }

  checkIfPasswordFieldsAreEmpty(validations) {
    const allFields =  qsa('input[type="password"]')
    const allFieldsLength = allFields.length

    if (validations.includes('password') || validations.includes('confirm_password')) {
      if (
        allFieldsLength >= 3 &&
        allFields.every(el => !el.value.length)
      ) {
        return true
      }
    }

    return false
  }

  getErrorMessage(el, test) {
    const val = qsa('[data-error-value]').filter(error => error.getAttribute('data-error-value') === el.id)
    const err = val.find(error => error.getAttribute('data-error-type') === test)

    return err ? err.innerHTML : ''
  }

  setError(error, el) {
    addClass(el, 'is-error')
    this.$els.error.appendChild(stringToDOM(`<span>${error}</span>`))
  }

  validate(e) {
    e.preventDefault()

    removeClass(this.$els.error.parentElement, 'is-error')
    this.$els.error.innerHTML = ""

    const schema = {
      email: {
        validate(val) {
          return validator.isEmail(val)
        }
      },

      password: {
        validate(val) {
          return validator.isAlphanumeric(val) && validator.isLength(val, 6)
        }
      },

      confirm_password: {
        validate(val, compare) {
          return  validator.equals(val, compare[0])
        }
      },

      numeric: {
        validate(val) {
          return validatos.isNumeric(val)
        }
      }
    }

    const errors = []
    //validating fields
    this.inputs.forEach(el => {
      const parent = el.parentElement.parentElement.parentElement
      removeClass(parent, 'invalid')
      const data = el.getAttribute('data-validate')

      if (!data) return
      const validations = data.split(',')

      if (this.checkIfPasswordFieldsAreEmpty(validations)) {
        return
      }

      if (validations.includes('required')) {
        if (
          (el.type === "checkbox" && !el.checked) ||
          (el.nodeName.toLowerCase() === "select" && parseInt(el.value) < 0) ||
          (!el.value.length)
        ) {
          addClass(parent, 'invalid')
          errors.push(this.getErrorMessage(el, 'required'))
          return
        }
      }

      validations.filter(val => val !== 'required').forEach(validate => {
        const test = schema[validate]

        if (!test) {
          return
        }

        const passwordFields = this.inputs.filter(el => el.getAttribute('data-validate') ? el.getAttribute('data-validate').includes('confirm_password') : '').map(obj => obj.value)

        if (!test.validate(el.value, passwordFields)) {
          addClass(parent, 'invalid')
          errors.push(this.getErrorMessage(el, validate))
        }
      })
    })

    if (errors.length) {
      this.setError(errors[0], this.$els.error.parentElement)
    } else {
      removeClass(this.$els.error.parentElement, 'is-error')

      const obj = this.inputs.map(el => ({ id: el.id, value: el.checked || el.value })).filter(el => el !== this.$els.submit)
      obj.push({ id: "lang", lang: document.documentElement.lang })

      const action = this.$els.submit.getAttribute('data-submit')
      
      axios.post(action, obj).then(response => {
        const { url, success, message, tracking } = response.data

        
        if (tracking) {
          track.ga('generate_lead', 'engagement')
          track.fb('Lead')
        }

        if (success) {
          if (url) {
            window.location = url
          } else {
            events.emit('form_success')
          }
        } else {
          this.setError(message, this.$els.error.parentElement)
        }
      })
    }
  }
}