import { Component } from 'yuzu'
import { TweenMax, Power2 } from 'gsap'
import { removeClass, addClass, isMobile } from '../base/utils'
import Scrollbar from 'smooth-scrollbar'
import events from '../events'

export default class ProductForm extends Component {
  static root = '.c-product-detail-form'

  selectors = {
    'background': '[data-background]',
    'scroller': '[data-scroller]',
    'panel': '[data-panel]',
    'close': '[data-close]',
    'error': '[data-error]',
    'form': '[data-form]',
  }

  listeners = {
    'click @background': event => {
      this.setState(() => ({
        open: false
      }))
    },

    'click @close': event => {
      this.setState(() => ({
        open: false
      }))
    }
  }

  state = {
    open: false
  }

  actions = {
    open: 'sync'
  }

  setup () {
    this.addScrollbar()
    this.addListeners()
  }

  addScrollbar () {
    this.scroller = isMobile() ? this.$els.scroller : Scrollbar.init(this.$els.scroller, {})
  }

  addListeners () {
    events.on('open_product_form', payload => {
      this.setState(() => ({
        open: payload
      }))
    })

    events.on('form_success', () => {
      const { $el } = this
      const className = `${$el.classList[0]}--sent`
      addClass($el, className)
    })
  }

  clearScroll () {
    if (isMobile()) {
      console.log(this.scroller)
      this.scroller.scrollTo(0, 0)
    } else {

    }
  }

  clearForm () {
    const { form, error } = this.$els

    const fields = form.querySelectorAll('.input-group')
    const inputs = form.querySelectorAll('input,textarea')

    removeClass(form, 'invalid')
    removeClass(error, 'is-error')
    
    for (let i = fields.length - 1; i >= 0; --i) {
      removeClass(fields[i], 'invalid')
    }

    for (let i = inputs.length - 1; i >= 0; --i) {
      const input = inputs[i]
      if ('checkbox' === input.type) {
        input.checked = false
      } else if ('hidden' !== input.type) {
        input.value = ''
      }
    }
  }

  sync () {
    const { $el } = this
    const { open } = this.state
    const { body } = document
    const classNameSent = `${$el.classList[0]}--sent`
    const classNameHidden = `${$el.classList[0]}--hidden`
    const ease = Power2.easeOut
    const clearProps = 'all'
    const duration = .6
    if (open) {
      this.clearForm()
      this.clearScroll()
      TweenMax.fromTo($el, duration, { opacity: 0 }, { opacity: 1, ease, 
        onStart: () => {
          removeClass($el, classNameHidden)
          addClass(body, 'overlay--open')
        } })
    } else {
      TweenMax.to($el, duration, { opacity: 0, ease, clearProps, 
        onStart: () => removeClass(body, 'overlay--open'),
        onComplete: () => {
          addClass($el, classNameHidden)
          removeClass($el, classNameSent)
        } })
    }
  }

  mounted () {
    this.setup()
  }
}