import { Component } from 'yuzu'
import { addClass, removeClass, hasClass } from '../base/utils'

export default class NavPanel extends Component {

  selectors = {
    'back': '[data-menu-back]',
    'main': '[data-main-menu]',
    'items[]': '[data-menu-item]',
  }

  listeners = {
    'click @items': event => {
      const { items } = this.$els
      const target = event.currentTarget
      const index = items.indexOf(target)
      if (hasClass(target, 'sub')) {
        event.preventDefault()
        this.setState(() => ({ hidden: true }))
        this.emit('sub', { open: true, index })
      }
    },

    'click @back': event => {
      event.preventDefault()
      this.setState(() => ({ hidden: false }))
      this.emit('sub', { open: false })
    },
  }

  state = {
    hidden: false,
    open: false,
  }

  actions = {
    hidden: 'hide',
    open: 'toggle',
  }

  setup () {
    const $el = this.$el
    const className = `${$el.classList[0]}--ready`
    addClass($el, className)
  }

  toggle () {
    const $el = this.$el
    const { open } = this.state 
    const className = `${$el.classList[0]}--active`
    const action = open ? addClass : removeClass
    action($el, className)
    this.emit('open', open)
  }

  hide () {
    const { hidden } = this.state 
    const { main, back } = this.$els
    const mainClassName = `${main.classList[0]}--hidden`
    const backClassName = `${back.classList[0]}--active`
    const mainAction = hidden ? addClass : removeClass
    const backAction = hidden ? addClass : removeClass
    mainAction(main, mainClassName)
    backAction(back, backClassName)
  }

  mounted () {
    this.setup()
  }
}