import { Component } from 'yuzu'
import { TweenMax } from 'gsap'
import { qs, qsa, addClass, removeClass, hasClass, isTouch, parents } from '../base/utils'

export default class NavBar extends Component {

  selectors = {
    'items[]': '[data-nav-bar-item]',
  }

  listeners = {
    'mouseenter @items': event => {
      if (isTouch()) return
      event.preventDefault()
      const target = event.currentTarget
      this.toggleSub(target, true)
    },

    'mouseleave @items': event => {
      if (isTouch()) return
      event.preventDefault()
      const target = event.currentTarget
      this.toggleSub(target, false)
    },

    'touchstart @items': event => {
      if (!isTouch()) return
      const target = event.currentTarget
      const dropdown = qs('.dropdown', target)
      const dropitem = hasClass(event.target, 'dropdown-item')
      if (dropdown && !dropitem) {
        event.preventDefault()
        this.toggleSub(target, true)
      }
    }
  }

  state = {
    hidden: false,
    scrolling: false,
  }

  actions = {
    hidden: 'toggle',
    scrolling: 'update',
  }

  toggle () {
    const $el = this.$el
    const { items } = this.$els
    const { hidden } = this.state 
    const className = `${$el.classList[0]}--hidden`
    const action = hidden ? addClass : removeClass
    action($el, className)
    for (let item of items) {
      if (hasClass(item, 'hover')) {
        this.toggleSub(item, false)
      }
    }
  }

  toggleSub (target, toggle) {
    const className = 'hover'
    const { items, panel } = this.$els
    if (toggle) {
      for (let $item of items) {
        const $panel = qs('.dropdown-content--panel', $item)
        const length = $panel ? qsa('ul li', $panel).length : 0
        const action = $item == target ? addClass : removeClass
        action($item, className)
        if ($item == target && length > 0) {
          const height = 50 + 30 * length
          TweenMax.set($panel, { height })
        } else if (length > 0) {
          TweenMax.set($panel, { clearProps: 'all' })
        }
      }
    }
  }

  update () {
    const { scrolling } = this.state
    const { items } = this.$els
    const className = 'hover'

    let target
    let count = 0
    for (let item of items) {
      if (hasClass(item, className)) {
        target = item
        ++count
      }
    }

    if (scrolling && count > 0) {
      const $panel = qs('.dropdown-content--panel', target)
      TweenMax.set($panel || {}, { clearProps: 'all' })
      removeClass(target, className)
    }
  }

  scroll (data) {
    const { deltaY } = data
    this.setState(() => ({ scrolling: deltaY != 0 }))
  }

  pointerStart (event) {
    const { target } = event
    const prevent = !parents(target, '.hover')
    if (prevent) this.toggleSub(null, true)
  }

  mounted () {
    return
  }
}