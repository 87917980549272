import { Component } from 'yuzu'
import { TweenMax } from 'gsap/all'
import { qs, qsa } from '../base/utils';

export default class UserArea extends Component {

  static root = '.l-user-area'

  mounted () {
    window.addEventListener('DOMContentLoaded', () => {
      this.setHeight()
    })

    setTimeout(() => {
      this.setHeight()
    }, 500)

    window.addEventListener('resize', () => {
      this.setHeight()
    })
  }

  setHeight() {
    const tabs = qs('.c-user-tabs')
    const h = tabs.getBoundingClientRect().height
    const totalH = h + 12
    const l = qsa('.c-user-tab').length
    const tabsH = qs('.c-user-tab').getBoundingClientRect().height * l

    TweenMax.set(qsa('[data-height]'), {
      height: totalH - tabsH
    })
  }
}