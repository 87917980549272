import { TimelineMax, TweenMax, Power2, Expo } from 'gsap'
import lottie from 'lottie-web'

// eslint-disable-next-line no-extend-native
Number.prototype.clamp = function(min, max) {
  return Math.min(Math.max(this, min), max)
}

const animations = new Map()
const lerp = (a, b, n) => ((1 - n) * a) + (n * b)

animations.set('stagger', (el) => {
  const tl = new TimelineMax({ delay: 2 })
  const split = new SplitText(el, { type: 'lines', linesClass: 'lines' })

  tl
    .staggerFrom(split.lines, 0.7, {
      yPercent: 30,
      autoAlpha: 0,
      ease: Power2.easeOut
    }, 0.05)

  return tl
})

animations.set('fade', (el) => {
  TweenMax.fromTo(el, 1.2, {
    autoAlpha: 0
  }, {
    autoAlpha: 1,
    ease: Power2.easeOut,
    clearProps: 'all'
  })
})

animations.set('svg', container => {
  container.enterAnimation.play()
})

animations.set('top', (el) => {
  TweenMax.fromTo(el, 1.2, {
    autoAlpha: 0,
    yPercent: 10
  }, {
    autoAlpha: 1,
    yPercent: 0,
    ease: Power2.easeOut,
    clearProps: 'all'
  })
})

animations.set('parallax', (el, i) => {
  const { top, height } = el.getBoundingClientRect()
  const wH = window.innerHeight
  const t = (top - ((wH - height) / 2))
  const transform = t.clamp(-wH, wH) / wH

  TweenMax.set(el, {
    y: el.getAttribute('data-parallax') * transform
  })
})

animations.set('crazy', (el, i) => {
  const { top, height } = el.getBoundingClientRect()
  const wH = window.innerHeight
  const t = (top - ((wH - height) / 2))
  const transform = t.clamp(-wH + (height), wH) / wH

  const odd = i % 2 ? 1 : -1

  TweenMax.set(el, {
    // x: 40 * Math.abs(transform) * odd,
    z: 80 * Math.abs(transform) * odd,
    rotationY: 40 * Math.abs(transform) * odd,
    rotationX: 20 * Math.abs(transform)
  })
})


export default animations
