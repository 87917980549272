export default {
  ga (action, category) {
    if (window.gtag === undefined) return
    console.log(':: gtag ::', action, category)
    gtag('event', action, {
      event_category: category
    })
  },

  fb (action) {
    if (window.fbq === undefined) return
    console.log(':: fbq ::', action)
    fbq('track', action)
  }
}