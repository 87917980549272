import { Component } from 'yuzu'
import { TweenMax } from 'gsap'
import { addClass } from '../base/utils'
import { TimelineMax } from 'gsap/all';
import { round } from 'lodash'
import events from '../events'

Number.prototype.clamp = function(min, max) {
  return Math.min(Math.max(this, min), max)
}

export default class SwipeButton extends Component {
  static root = '[data-swipe-button]'

  selectors = {
    slider: '.slider',
    'defaultText[]': '[data-swipe-default]',
    'unlockedText[]': '[data-swipe-unlocked]',
  }

  state = {
    initialMouse: 0,
    slideMovementTotal: 0,
    mouseIsDown: false
  }

  listeners = {
    'touchstart @slider': e => this.touchstart(e),
    'touchend @slider': e => this.touchend(e),
  }

  mounted() {
    const touchmove = this.touchmove.bind(this)
    document.body.addEventListener('touchmove', e => touchmove(e))

    this.tl = new TimelineMax({ paused: true })

    this.tl
      .add('start')
      .to(this.$els.defaultText, 0.5, {
        autoAlpha: 0
      })
      .to(this.$els.unlockedText, 0.5, {
        autoAlpha: 1
      })
      .fromTo(this.$el, 1, {
        backgroundColor: '#FFD94A'
      }, {
        backgroundColor: '#efefef'
      }, 'start')
  }

  touchstart(e) {
    e.preventDefault()
    this.setState({ mouseIsDown: true })
    this.setState({ slideMovementTotal: this.$el.clientWidth - this.$els.slider.clientWidth })
    this.setState({ initialMouse: event.touches[0].pageX })
  }

  touchend(e) {
    e.preventDefault()
    if (!this.state.mouseIsDown) return

    const currentMouse = event.changedTouches[0].pageX
    const relativeMouse = currentMouse - this.state.initialMouse

    if (relativeMouse < this.state.slideMovementTotal) {
      this.setState({ mouseIsDown: true })

      const tl = new TimelineMax({
        onComplete: () => { this.setState({ mouseIsDown: false }) }
      })

      tl
        .add('start')
        .to(this.$els.unlockedText, 0.3, {
          autoAlpha: 0
        })
        .to(this.$els.defaultText, 0.3, {
          autoAlpha: 1
        })
        .fromTo(this.$el, 0.8, {
          backgroundColor: '#efefef'
        }, {
          backgroundColor: '#FFD94A'
        }, 'start')
        .to(this.$els.slider, 0.3, {
          x: 0
        }, 'start')

      return
    }

    this.setState({ mouseIsDown: false })
    addClass(this.$el, 'unlocked')
    events.emit('swipe_unlocked')
  }

  touchmove(e) {
    e.preventDefault()
    if (!this.state.mouseIsDown) return

    const currentMouse = event.touches[0].pageX
    const relativeMouse = currentMouse - this.state.initialMouse
    const slidePercent =  round((relativeMouse / this.state.slideMovementTotal).clamp(0, 1), 2)

    if (relativeMouse <= 0) {
      this.tl.progress(0)
      TweenMax.set(this.$els.slider, { x: 0 })
      return
    }

    if (relativeMouse >= this.state.slideMovementTotal) {
      this.tl.progress(1)
      TweenMax.set(this.$els.slider, { x: this.state.slideMovementTotal })
      return
    }

    this.tl.reversed(false).timeScale(1)
    this.tl.progress(slidePercent)
    TweenMax.set(this.$els.slider, { x: relativeMouse })
  }
}