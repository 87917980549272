/**
* Main Application File
*
* Use for bootstrapping large application
* or just fill it with JS on small projects
*
*/
import { isTouch, addClass, removeClass, qsa, qs } from './base/utils'
import EnterAnimations from './scrollbar/enter-animations'
import Scrollbar from './scrollbar'
import lottie from 'lottie-web'

import ScrollToTop from './components/scroll-to-top'
import HeaderTools from './components/header-tools'
import HeroEffect from './components/hero-effect'
import CardSwiper from './components/card-swiper'
import Newsletter from './components/newsletter'
import SharePage from './components/share-page'
import Brochures from './components/Brochures'
import CartList from './components/cart-list'
import CartProd from './components/cart-prod'
import ProductLeaf from './components/prod-leaf'
import ProductForm from './components/prod-form'
import AccordionGroup from './components/accordion'
import AdvancedSearch from './components/advanced-search'
//import CookieBar from './components/cookie-bar'
import Payment from './components/payment'
import Input from './components/input'
import Form from './components/form'
import Overlay from './components/overlay'
import Warning from './components/warning'
import UserArea from './components/user-area'
import SwipeButton from './components/swipe-button'
//import Quantity from './components/quantity'

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    removeClass(document.documentElement, 'loading')
  }, 100)

  if (!isTouch()) {
    addClass(document.documentElement, 'no-touchevents')
  }

  const widgets = []
  const components = [
    HeaderTools,
    ScrollToTop,
    HeroEffect,
    CardSwiper,
    Newsletter,
    SharePage,
    Brochures,
    CartList,
    CartProd,
    AccordionGroup,
    ProductLeaf,
    ProductForm,
    Input,
    Form,
    AdvancedSearch,
    SwipeButton,
    UserArea,
    //CookieBar,
    Overlay,
    Warning,
    Payment,
    //Quantity,
  ]

  components.forEach(Component => {
    const elements = qsa(Component.root, document)
    if (elements.length > 0) {
      elements.forEach(element => {
        const widget = new Component().mount(element)
        widgets.push(widget)
      })
    }
  })

  window.addEventListener('touchstart', event => dispatch('pointerStart', event), { passive: false })
  window.addEventListener('mousedown', event => dispatch('pointerStart', event), { passive: false })
  window.addEventListener('resize', () => dispatch('resize'))
  //document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)

  const icons = qsa('.svg-json')
  icons.forEach(container => {
    container.enterAnimation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      autoplay: false,
      path: container.getAttribute('data-path')
    })
  })

  const animations = new EnterAnimations()

  Scrollbar.add((dir) => {
    dispatch('scroll', dir)
  })

  const dispatch = (fn, options) => {
    for (let widget of widgets) {
      if (widget[fn])
        widget[fn](options)
    }
  }
})