import { Component } from 'yuzu'
import { addClass, removeClass, hasClass } from '../base/utils'

export default class SubPanel extends Component {

  selectors = {
    'navs[]': '[data-sub-nav]',
  }

  state = {
    open: false,
    index: -1,
  }

  actions = {
    index: 'setIndex',
    open: 'toggle',
  }

  setIndex () {
    const className = 'active'
    const { navs } = this.$els
    const { index } = this.state
    const current = navs[index]
    for (let nav of navs) {
      if (hasClass(nav, className))
        removeClass(nav, className)
    }
    if (current) 
      addClass(current, className)
  }

  toggle () {
    const $el = this.$el
    const { open } = this.state 
    const className = `${$el.classList[0]}--active`
    const action = open ? addClass : removeClass
    action($el, className)
  }

  mounted () {
    return
  }
}