import { Component } from 'yuzu'
import { TweenMax, Expo } from 'gsap/all'
import { isMobile, removeClass, addClass } from '../base/utils'
import { parents, hasClass } from '../base/utils'
import search from '../services/search'
import track from '../tracking'

export default class AdvancedSearch extends Component {

  static root = '.c-advanced-search'

  selectors = {
    'select[]': 'select',
    'submit': '[data-submit]',
  }

  listeners = {
    'change @select': event => {
      const select = event.currentTarget
      this.setState(() => ({ 
        [select.id]: select.value
      }))
    },

    'click @submit': event => {
      event.preventDefault()
      const valid = this.state.a.length > 0
      if (valid) this.send('submit').then(response => {
        const { url, success } = response.data
        if (success) {
          track.ga('search', 'engagement')
          window.open(url, '_self')
        }
      })
    }
  }

  state = {
    a: '',
    b: '',
    c: '',
  }

  actions = {
    a: 'syncA',
    b: 'syncB',
  }

  send (action) {
    const service = this.$el.dataset.action
    const { a, b, c } = this.state
    const result = { service, action, a, b, c }
    return search.send(result)
  }

  syncA (value, oldvalue) {
    if (value != oldvalue)
      this.update(value, 'b', 1)
  }

  syncB (value, oldvalue) {
    if (value != oldvalue)
      this.update(value, 'c', 2)
  }

  send (action, select) {
    const { a, b, c } = this.state
    const service = this.$el.dataset.action
    const result = { service, action, select, a, b, c }
    return search.send(result)
  }

  update (value, id, index) {
    const $selects = this.$els.select
    this.setState(() => ({ [id]: '' }))
    if (value)
      this.send('search', id).then(response => {
        const { options } = response.data
        this.enable(true, $selects[index], options)
      })
    else this.enable(false, $selects[index], value.length==0)
  }

  enable (enable, select, options) {
    for(let i = select.length - 1 ; i >= 0 ; i--) { if (i>0) select.remove(i) }
    const row = parents(select, 'ul li')
    if (enable) {
      for (const option of options) {
        const $option = document.createElement('option')
        $option.value = option.id
        $option.innerHTML = option.label
        $option.selected = option.selected
        select.appendChild($option)
      }
      select.disabled = false
      if (hasClass(row, 'disabled'))
        removeClass(row, 'disabled')
    } else {
      if (!hasClass(row, 'disabled'))
        addClass(row, 'disabled')
      select.disabled = true
    }
  }

  mounted () {
    this.setState(() => ({ 
      a: this.$els.select[0].value,
      b: this.$els.select[1].value,
      c: this.$els.select[2].value,
    }), true)
    
    /*setTimeout(() => {
      this.setWidth()
    })

    window.addEventListener('resize', () => {
      if (isMobile()) {
        TweenMax.set(this.$els.select, { clearProps: 'all' })
      } else {
        this.setWidth()
      }
    })*/
  }

  setWidth() {
    if (isMobile()) return
    const width = this.$els.select.reduce((max, n) => n.clientWidth > max ? n.clientWidth : max, 0)
    TweenMax.set(this.$els.select, { width })
  }
}