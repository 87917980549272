import { Component } from 'yuzu'
import events from '../events'

export default class Brochures extends Component {

  static root = '.c-brochures'

  selectors = {
    'cta[]': '.c-news-card__cta a',
  }

  listeners = {
    'click @cta': event => {
      if (!this.validate(event)) {
        event.preventDefault()
        events.emit('open_overlay', true)
      }
    }
  }

  validate (event) {
    const target = event.currentTarget
    const dataset = target.dataset
    return dataset.auth !== undefined
  }
}