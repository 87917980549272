import { Component } from 'yuzu'
import { TweenMax, Power2 } from 'gsap'
import { removeClass, addClass } from '../base/utils'
import events from '../events'

export default class Overlay extends Component {

  static root = '.overlay'

  selectors = {
    'background': '[data-background]',
    'panel': '[data-panel]',
  }

  listeners = {
    'click @background': event => {
      this.setState(() => ({
        open: false
      }))
    }
  }

  state = {
    open: false
  }

  actions = {
    open: 'sync'
  }

  setup () {
    this.addListeners()
  }

  addListeners () {
    events.on('open_overlay', payload => {
      this.setState(() => ({
        open: payload
      }))
    })
  }

  sync () {
    const { $el } = this
    const { open } = this.state
    const { body } = document
    const className = `${$el.classList[0]}--open`
    const ease = Power2.easeOut
    const clearProps = 'all'
    const duration = .8
    if (open) 
      TweenMax.fromTo($el, duration, { opacity: 0 }, { opacity: 1, ease, 
        onStart: () => {
          addClass(body, className)
          addClass($el, className)
        } })
    else TweenMax.to($el, duration, { opacity: 0, ease, clearProps, 
        onStart: () => removeClass(body, className),
        onComplete: () => removeClass($el, className) })
  }

  mounted () {
    this.setup()
  }
}