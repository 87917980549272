import axios from 'axios'

export default {
  send ({ service, action, a, b, c, select }) {
    const { lang } = document.documentElement
    const formData = new FormData()
    formData.append('a', a || '')
    formData.append('b', b || '')
    formData.append('c', c || '')
    formData.append('action', action)
    formData.append('lang', lang)
    if (select)
      formData.append('select', select)
    return axios.post(service, formData)
  }
}