export default {
  store: [],

  template: 'product-cart-template',

  createElement ({ id, vat, title, image, price, total, quantity, required, available }) {
    const template = document.getElementById(this.template).innerText
    const item = document.createElement('li')
    item.className = 'gift'

    const data = {
      '{id}': id,
      '{vat}': vat,
      '{title}': title,
      '{image}': image,
      '{price}': price,
      '{total}': total,
      '{quantity}': quantity,
      '{required}': required,
      '{available}': available,
    }

    item.innerHTML = template.replace(
      /{id}|{vat}|{title}|{image}|{price}|{total}|{quantity}|{required}|{available}/gi
      , matched => data[matched]).trim()

    this.store.push(item)

    return item
  },

  clear () {
    if (this.store.length === 0) return

    for (const element of this.store) {
      element.remove()
    }

    this.store.splice(0, this.store.length)

    //console.log(this.store.length)
  }
}