const camelCaseRegExp = /-([a-z])/ig
const toCamelCase = str => str.replace(camelCaseRegExp, match => match[1].toUpperCase())

export const byId = id => document.getElementById(id)

export const byClassName = (selector, ctx = document) => {
  Array.toArray(ctx.getElementsByClassName(selector))
}

export const qs = (selector, ctx = document) => ctx.querySelector(selector)

export const qsa = (selector, ctx = document) => Array.from(ctx.querySelectorAll(selector))

export const toggleClass = (el, className) => {
  el.classList.toggle(className)
}

export const addClass = (el, className) => {
  el.classList.add(className)
}

export const removeClass = (el, className) => {
  el.classList.remove(className)
}

export const hasClass = (el, className) => {
  return el.classList.contains(className)
}

// export const hasClass = (el, className) => classie.has(el, className)

export const toNodeList = (elements) => new NodeList(elements)

export function handleEvent(eventName, {
  onElement,
  withCallback,
  useCapture = false
} = {}, thisArg) {
  const element = onElement || document.documentElement

  function handler(event) {
    if (typeof withCallback === 'function') {
      withCallback.call(thisArg, event)
    }
  }

  handler.destroy = function destroy() {
    return element.removeEventListener(eventName, handler, useCapture)
  }

  element.addEventListener(eventName, handler, useCapture)
  return handler
}

export function createconfig(o = {}, config) {
  return Object.assign({}, config, o)
}

export const stringToDOM = (string = '') => {
  const fragment = document.createDocumentFragment()
  const wrapper = fragment.appendChild(document.createElement('div'))

  wrapper.innerHTML = string.trim()
  return wrapper.children[0]
}

export function index(element) {
  const sib = element.parentNode.childNodes
  let n = 0

  for (let i = 0; i < sib.length; i += 1) {
    if (sib[i] === element) return n
    if (sib[i].nodeType === 1) n += 1
  }

  return -1
}

export function eq(parent, i) {
  return (i >= 0 && i < parent.length) ? parent[i] : -1
}

export function getDevice() {
  let device = window.getComputedStyle(document.body, '::after').getPropertyValue('content')
  device = device.replace(/('|")/g, '')

  return device
}

export function isMobile() {
  return !(getDevice() !== 'xs' && getDevice() !== 'sm')
}

export function calculateRatio(width, height) {
  return width / height
}

export function lerp(a, b, n) {
  return ((1 - n) * a) + (n * b)
}

export function viewportSize() {
  return {
    w: window.innerWidth,
    h: window.innerHeight,
    ratio: window.innerWidth / window.innerHeight
  }
}


export function roundNumber(n, p) {
	var p = p !== undefined ? Math.pow(10, p) : 1000
	return Math.round(n * p) / p
}

export function inViewport(e) {
  const { height, bottom, top, left, right, width } = e.getBoundingClientRect()
  const h = height || (bottom - top)
  const w = width || (right - left)
  const viewport = viewportSize()

  if (!h || !w) return false
  if (top > viewport.h || bottom < 0) return false
  if (right < 0 || left > viewport.w) return false

  return true
}

export const isTouch = () => (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))

export class NodeList {

  constructor(elements, ctx = document) {
    this.els = typeof elements === 'string' ? qsa(elements, ctx) : Array.from(elements)
  }

  toArray() {
    return this.els
  }

  eq(index) {
    return this.els[index]
  }

  indexOf(target) {
    return this.els.indexOf(target)
  }

  attr(attr, value) {
    const { els } = this
    const attrStr = toCamelCase(attr)
    if (value) {
      this.els.forEach(el => (el[attrStr] = value))
      return this
    }
    const el = els.length > 0 ? els[0] : undefined
    const hook = NodeList.attrHooks[attrStr]
    if (!el) {
      return undefined
    }
    return hook ? hook(el) : el[attrStr]
  }

  addClass(className) {
    this.els.forEach((el) => (classie.add(el, className)))
    return this
  }

  removeClass(className) {
    this.els.forEach((el) => (classie.remove(el, className)))
    return this
  }

  toggleClass(className, toggle) {
    const fn = toggle === undefined ? 'toggle' : (toggle ? 'add' : 'remove') //eslint-disable-line no-nested-ternary
    this.els.forEach((el) => (classie[fn](el, className)))
    return this
  }
}

NodeList.attrHooks = {
  'for': (el) => el.htmlFor,
  'class': (el) => el.className
}

export function degrees(radians) {
  return (radians * 180) / Math.PI
}

export function radians(degrees) {
  return (degrees * Math.PI) / 180
}

export function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function getScrollTop() {
  if (window.pageYOffset) return window.pageYOffset
  return document.documentElement.clientHeight
    ? document.documentElement.scrollTop
    : document.body.scrollTop
}

export function validateEmail (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function dispatch(element, eventName) {
  if (element != null) {   
    if (element.fireEvent) {
      element.fireEvent('on' + eventName)     
    } else {   
      var event = document.createEvent('Events')
      event.initEvent(eventName, true, false)
      element.dispatchEvent(event)
    }
  }
}

export function parents(elem, selector) {
  if (!Element.prototype.matches) {
	    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1
      }
	}

	// Get the closest matching element
	for (; elem && elem !== document; elem = elem.parentNode) {
		if (elem.matches(selector)) return elem
	}
	return null
}

export function isWebGLAvailable() {
  try { 
    var canvas = document.createElement( 'canvas' )
    return !! canvas.getContext( 'webgl' )
    return !! window.WebGLRenderingContext && 
      ( canvas.getContext( 'webgl' ) || 
        canvas.getContext( 'experimental-webgl' ) )
  } catch( e ) { return false; } 
}